
import { defineComponent, ref } from "vue";
import {
  CalendarOptions,
  EventApi,
  DateSelectArg,
  EventClickArg,
} from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import idLocale from "@fullcalendar/core/locales/id";
import { INITIAL_EVENTS, createEventId } from "@/core/helpers/event-utils";
import { EventList } from "@/core/types/gws-ap2tki/schedule/EventList";
import { ElTooltip } from "element-plus";

export default defineComponent({
  name: "ScheduleCalendar",
  props: {
    eventData: {
      type: Array,
      default: [] as EventList[],
    },
  },
  emits: ["edit-course"],
  components: {
    FullCalendar,
  },
  setup(props, { emit }) {
    const handleEditCourse = (id: string) => {
      emit("edit-course", id);
    };
    return {
      handleEditCourse,
    };
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        headerToolbar: {
          left: "",
          center: "",
          right: "",
        },
        initialView: "timeGridWeek",
        editable: false,
        allDaySlot: true,
        locale: idLocale,
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,
        dayHeaderFormat: {
          weekday: "long",
        },
        eventTextColor: "#fff",
        eventChange: this.handleRefetch,
        weekends: true,
        eventClick: this.handleEventClick,
        events: this.eventData,
        eventDisplay: 'list-item',
      } as CalendarOptions,
    };
  },
  methods: {
    handleEventClick(clickInfo: EventClickArg) {
      this.handleEditCourse(clickInfo.event.groupId);
    },
    handleRefetch() {
      (this.$refs.eventCal as any).handleRefetch();
    },
  },
});
