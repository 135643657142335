<template>
  <div class="schedule-app">
    <div class="schedule-app-main">
      <FullCalendar
        class="schedule-app-calendar"
        ref="eventCal"
        :options="calendarOptions"
      >
        <template v-slot:eventContent="arg">
          <b>{{ arg.timeText }}</b>
          <i>{{ arg.event.title }}</i>
        </template>
      </FullCalendar>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  CalendarOptions,
  EventApi,
  DateSelectArg,
  EventClickArg,
} from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import idLocale from "@fullcalendar/core/locales/id";
import { INITIAL_EVENTS, createEventId } from "@/core/helpers/event-utils";
import { EventList } from "@/core/types/gws-ap2tki/schedule/EventList";
import { ElTooltip } from "element-plus";

export default defineComponent({
  name: "ScheduleCalendar",
  props: {
    eventData: {
      type: Array,
      default: [] as EventList[],
    },
  },
  emits: ["edit-course"],
  components: {
    FullCalendar,
  },
  setup(props, { emit }) {
    const handleEditCourse = (id: string) => {
      emit("edit-course", id);
    };
    return {
      handleEditCourse,
    };
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        headerToolbar: {
          left: "",
          center: "",
          right: "",
        },
        initialView: "timeGridWeek",
        editable: false,
        allDaySlot: true,
        locale: idLocale,
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,
        dayHeaderFormat: {
          weekday: "long",
        },
        eventTextColor: "#fff",
        eventChange: this.handleRefetch,
        weekends: true,
        eventClick: this.handleEventClick,
        events: this.eventData,
        eventDisplay: 'list-item',
      } as CalendarOptions,
    };
  },
  methods: {
    handleEventClick(clickInfo: EventClickArg) {
      this.handleEditCourse(clickInfo.event.groupId);
    },
    handleRefetch() {
      (this.$refs.eventCal as any).handleRefetch();
    },
  },
});
</script>
<style lang="css">
h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b {
  /* used for event dates/times */
  margin-right: 3px;
}

.schedule-app {
  display: flex;
  width: 100%;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.schedule-app-sidebar-section {
  padding: 2em;
}

.schedule-app-main {
  flex-grow: 1;
  padding: 3em;
  width: 100%;
}

.fc {
  /* the calendar root */
  margin: 0 auto;
}
.fc-event-main > i {
  font-size: 12px;
  display: inline-grid;
  font-weight: 700;
}
.fc table tr {
  height: 4rem;
}
</style>
